
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    sponsors: [
      //Guld
      {
        name: "Kvardrat",
        url: "https://www.kvadrat.se/",
        icon: require("../assets/img/Kvadrat.png"),
        alt: "Länk till Kvadrats hemsida",
      },
      { 
        name: "Skatteverket",
        url: "https://www.skatteverket.se/",
        icon: require("../assets/img/SKV_RGB_st.png"),
        alt: "Länk till Skatteverket hemsida"
      },
      {
        name: "Umeå Universitet",
        url: "https://www.umu.se/",
        icon: require("../assets/img/UmeaUniversitat.png"),
        alt: "Länk till Umeå Universitets hemsida",
      },
      {
        name: "Pure IT",
        url: "http://www.pure.se/",
        icon: require("../assets/img/PureIT.svg"),
        alt: "Länk till Pure IT:s hemsida",
      },
      {
        name: "Biometria",
        url: "https://biometria.se/",
        icon: require("../assets/img/biometria_logo_liggande-rgb.png"),
        alt: "Länk till Biometria hemsida"
      },
      {
        name: "Algoryx",
        url: "https://www.algoryx.se/",
        icon: require("../assets/img/algoryx.png"),
        alt: "Länk till Algoryx hemsida",
      },
      {
        name: "Umecon",
        url: "https://umecon.se/",
        icon: require("../assets/img/UmeCon.jpg"),
        alt: "Länk till Umecons hemsida",
      },
      {
        name: "Metria",
        url: "https://metria.se/",
        icon: require("../assets/img/Metria_Logotyp.png"),
        alt: "Länk till Metrias hemsida",
        class: "metria",
      },
      {
        name: "Vitec",
        url: "https://www.vitecsoftware.com",
        icon: require("../assets/img/Vitec-Logo-vertical-software.jpg"),
        alt: "Länk till Vitecs hemsida",
      },

   
      //silver
      {
        name: "Clear Street",
        url: "https://clearstreet.io/",
        icon: require("../assets/img/clear_street_logo.png"),
        alt: "Länk till Clear Street hemsida",
      },
      {
        name: "Omegapoint",
        url: "https://www.omegapoint.se/",
        icon: require("../assets/img/OmegaPoint.png"),
        alt: "Länk till Omgeapoints hemsida",
      },
      {
        name: "Xlent",
        url: "https://www.xlent.se/",
        icon: require("../assets/img/Xlent.jpg"),
        alt: "Länk till Xlents hemsida",
      },
      {
        name: "Dizparc",
        url: "https://dizparc.se/",
        icon: require("../assets/img/Dizparc.png"),
        alt: "Länk till Dizparcs hemsida",
      },
      {
        name: "Sartorius",
        url: "https://www.sartorius.com/",
        icon: require("../assets/img/Sartorius-Logo.jpg"),
        alt: "Länk till Sartorius hemsida"
      },
      { 
        name: "Upkeeper",
        url: "https://www.upkeeper.se/",
        icon: require("../assets/img/upkeeper-logo.png"),
        alt: "Länk till Upkeeper hemsida"
      },
      //Brons
      { 
        name: "Bulbul",
        url: "https://www.bulbul.se/",
        icon: require("../assets/img/Logo-bulbul-yellow.png"),
        alt: "Länk till Bulbul hemsida"
      },
      { 
        name: "Tietoevry",
        url: "https://www.tietoevry.com/se/",
        icon: require("../assets/img/tietoevry.png"),
        alt: "Länk till Tietoevrys/ hemsida"
      },
    ],
  }),
});
